<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <ShoppingCart/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ShoppingCart from '../components/ShoppingCart.vue'

export default {
  components: { ShoppingCart },
};
</script>