<template>
  <div>
    <div class="header_holder text-center ma mb-6">
        <div class="rounder_top mb-2">
            <div class="card_header ubuntu_b primer">
                Moja narudžbenica
            </div>
        </div>
    </div>
    <div class="text-center"  v-show="!products.length">
      <p><i>Košarica je prazna</i></p>
    </div>
    <div v-show="products.length">
      <div v-for="(product, index) in products" :key="product.id+'-'+product.quantity" class="product_item primer rounder">
        <div class="naslov clearfix">
            <div class="product_title">{{ product.title }}</div>
            <v-img v-if="product.composition.includes(1)" :src="require(`@/assets/img/guda.png`)" :contain='true' width="35"></v-img>
            <v-img v-if="product.composition.includes(2)" :src="require(`@/assets/img/jaga.png`)" :contain='true' width="35"></v-img>
            <v-img v-if="product.composition.includes(3)" :src="require(`@/assets/img/tele.png`)" :contain='true' width="35"></v-img>
            <v-img v-if="product.composition.includes(9)" :src="require(`@/assets/img/jagnje.png`)" :contain='true' width="35"></v-img>
            <div class="product_id">{{ product.id }}</div>
          </div>
          <div class="text-center cart-action_product">
            <v-row>
              <v-col cols="6">
                <div class="mt-1 h32 net_price text-left">
                  {{ product.parent_price | currency }} / {{product.jm}}
                </div>
              </v-col>
              <v-col cols="6" class="text-right cart_item">
                <v-chip
                  class="product_chip"
                  color="transparent"
                  outlined
                >
                  <div class="cart_item_total">Iznos: {{ product.parent_price * product.quantity | currency }}</div>
                  <v-icon class="plus-minus" small left @click="removeProductFromCart({id:product.id, step:product.step})">fas fa-minus</v-icon>
                  <v-btn text class="qty_no_btn" @click="openInput(index)" v-if="open_input!==index">{{ product.quantity }}</v-btn>
                  <v-text-field v-if="open_input===index" :value="product.quantity" class="qty_no" @keyup="isNumber(product.step, product.id, $event)" @blur="openInput(index)"></v-text-field>
                  <v-icon class="plus-minus" small right @click="addProductToCart({id:product.id, step:product.step})">fas fa-plus</v-icon>
                </v-chip>
              </v-col>
            </v-row>
            <!--<router-link :to="'/product/'+product.id">
              <v-btn color="secondary" class="details_fab">
                <span class="mob">More<br>info</span>
                <span class="desktop">More info</span>
                <v-icon medium color="white">fas fa-angle-right</v-icon>
              </v-btn>
            </router-link>-->
          </div>
      </div>
    </div>
    <div :class="'rounder total_holder mt-8'+(group===4?' groupFour':'')">
        <div class="cart_total ma-6">
            <p class="total_cart" color="black">
              <span class="total_text">Očekivani iznos</span><br>
              <b>{{ total | currency }}</b><br>
            </p>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Dan isporuke narudžbe"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    class="date_holder"
                  ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  scrollable
                  first-day-of-week="1"
                  :allowed-dates="dozvoljeniDatumi"
                  :min="start"
                  :max="end"
              >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Odustani</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">Potvrda</v-btn>
              </v-date-picker>
            </v-dialog>
        </div>
        <v-btn :disabled="!products.length" @click="checkout()" class="rounder_bottom submit_btn ubuntu_b" color="secondary">
          Završi narudžbu
          <div v-if="products.length" class="white_circle"><v-img :src="require(`@/assets/img/right_red.png`)" contain></v-img></div>
        </v-btn>
    </div>
    
    <div class="clearfix mt-4 mb-8">
      <div class="float-left">
        <router-link to="/shop" class="linker">
          <v-btn small text>
            <v-icon small class="mr-3">fas fa-arrow-left</v-icon> Nastavi kupnju
          </v-btn>
        </router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
//import { conf } from '@/config.js'
import { addDays } from '../addDays'
import EventBus from '@/event-bus'
export default {
  data() {
    return {
      timer:null,
      open_input: -1,
      date: null,
      today: null,
      dateFormatted: null,
      modal: false,
    }
  },
  computed: {
    ...mapState({
      checkoutStatus: state => state.cart.checkoutStatus
    }),
    ...mapGetters({
      poslovnice: 'users/getPoslovnice',
      products: 'cart/cartProducts',
      ordered: 'cart/cartProductsOrderedByCategories',
      user: 'user/getUser',
      group: 'users/getGroup',
      time: 'cart/getTime',
      total: 'cart/cartTotalPrice',
      userCredentials: 'user/getCredentials',
    }),
    user_type(){
      return (typeof this.user.user_type !== 'undefined')?this.user.user_type:3
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    start(){
      return addDays(this.today,(parseInt(this.time.substring(11, 13))<20)?0:1)
    },  
    end(){
      return addDays(this.today,60)
    }
  },
  watch: {
    date () {
      
      if(!(this.$store.getters['users/getPoslovnice'].length === 1 && this.$store.getters['users/getPoslovnice'][0]['document']!==null&&this.user_type!=5)){
        let now = this.time
        let h = parseInt(now.substring(11, 13))
        console.log(h+' '+this.date +' '+ this.today)
        if((h>=5&&h<=20)&&this.date === this.today){
          this.$store.commit('message_modal', { title: 'UPOZORENJE', text: 'Poštovani, sve narudžbe za danas trebate poslati do najkasnije 05:00 sati. Zakasnili ste s ovom narudžbom te vam nismo u mogućnosti obećati da ćete naručenu robu i dobiti. Hvala vam na razumijevanju!' }, { root: true })
        }
      }
      this.dateFormatted = this.formatDate(this.date)
    },
    today(){
      this.todayFormatted = this.formatDate(this.today)
    }
  },
  methods: {
    ...mapActions('cart', [
      'addProductToCart',
      'removeProductFromCart',
    ]),
    openInput(index){
        if(this.open_input == index ){
            this.open_input = -1
        }else{
            this.open_input = index
        }
    },
    dozvoljeniDatumi(val){
      console.log('group',this.group);
      console.log('date',this.val);
      if(![4,6].includes(this.group)){
        return true
      }
      var date = new Date(val)
      const day = date.getDay()
      console.log(day)
      switch(this.group){
        case 4:
          return [1,3,5].includes(day)
        case 6:
          return [5].includes(day)
      }
    },
    checkout () {
      this.$store.dispatch('cart/set_ord_date', this.date)
      this.$store.dispatch('cart/checkout')
    },
    formatDate (date) {
      if (!date) return null
      moment.locale('hr')
      let day_name = moment(this.date).format('dddd')
      const [year, month, day] = date.split('-')
      return `Narudžba za dan: ${day_name}, ${day}.${month}.${year}. Odaberite drugi datum isporuke.`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  created () {
    
    /*let offset = (moment().tz(conf.TZ).utcOffset())/60
    let today = moment().tz(conf.TZ).add(-offset, 'hours').toISOString()
    let h = parseInt(today.substring(11, 13))
    if(this.$store.getters['users/getPoslovnice'].length === 1 && this.$store.getters['users/getPoslovnice'][0]['document']!==null&&this.user_type!=5){
      this.date = moment().tz(conf.TZ).add((h<20)?0:1, 'days').toISOString().substring(0, 10)
    }else{
      this.date = moment().tz(conf.TZ).utc().add((h<5)?0:1, 'days').toISOString().substring(0, 10)
    }
    this.today = today.substring(0, 10)*/
    
  },
  beforeCreate(){
    const event_name = 'time_'+Math.floor(Math.random() * 10)
    this.$store.dispatch('cart/get_time', {event: event_name})
    EventBus.$on(event_name,() => {
        this.today = this.time.substring(0, 10)
        let h = parseInt(this.time.substring(11, 13))
        console.log(h)
        console.log(this.today)
        if(this.$store.getters['users/getPoslovnice'].length === 1 && this.$store.getters['users/getPoslovnice'][0]['document']!==null&&this.user_type!=5){
          this.date = addDays(this.today,(h<20)?0:1)
        }else{
          if(![4,6].includes(this.group)){
            this.date = addDays(this.today,(h<5)?0:1)
          }else{
            this.date = addDays(this.today,(h<5)?0:1)
            while(!this.dozvoljeniDatumi(this.date)){
              this.date = addDays(this.date,1)
            }
          }
        }
        console.log(this.date);
        this.$store.dispatch('cart/set_ord_date', this.date)
    })
    if(this.$store.getters['user/getCredentials'].narucivanje == 0) this.$router.push({ name: 'not_auth' })
  }
}
</script>
<style scoped>
  .product_chip{
    position:relative;
  }
  .cart_item_total{
    color: #000;
    position:absolute;
    top:-25px;
    width:140%;
    left:-20%;
    text-align: center;
  }
  
  .product_item{
    position:relative;
    margin-bottom:10px;
  }
  .cart_items_list{
    padding:0;
    margin:0 -10px;
  }
  .total_holder{
    background-color: #F1F1F1;
    box-shadow: 0 4px 3px rgba(0,0,0,.1);
    overflow: hidden;
  }
  .cart_total b{
    font-size: 1.2rem;
  }
  .shipment_text{
    color:rgb(105, 105, 105);
  }
  .total_text{
    color:rgb(105, 105, 105);
    text-transform:uppercase;
  }
  .total_cart{
    text-align: left;
    padding-right:5px;
  }
  .product_item{
    position:relative;
    min-height:35px;
  }
  .product_text{
    line-height:35px;
    position: relative;
    width:calc(100%-90px);
    display: block;
    margin-right:90px;
  }
  .groupFour{
    background-color: #93dae4 !important;
    color: rgb(255, 255, 255) !important;
  }
</style>
